#slider {
	padding: 0;
	.slide { height: 550px; 
		@include media-breakpoint-down(sm) { height:450px; }
		@include media-breakpoint-down(xs) { height:350px; }
	}	
	div.h1 { 
		margin:150px 0 30px; font-size:120px; font-weight:700; text-shadow: 2px 2px 2px #000; 
		text-transform:uppercase; font-family: 'Roboto', sans-serif; font-style:italic; color:#fff; 
		@include media-breakpoint-down(sm) { margin-top:90px }
		@include media-breakpoint-down(xs) { margin:50px }		
		
	}
	@include media-breakpoint-up(lg) { #slider .container { max-width:900px; }  }
}

/* TABS */
.tab-set {
	margin: 0 0 -1px 0;
	padding:0;
	list-style:none;
	> li { 
		display:inline-block;
		padding: 1rem; 
		background:$light-gray; 
		border-top: 1px solid $almost-gray;
		border-left: 1px solid $almost-gray;
		cursor: pointer;
		font-size: 1.125rem;
		text-transform: uppercase;  
		&:last-child { 
			border-right: 1px solid $almost-gray;
		}
		&.active {
			background: #fff; 
			border-top: 3px solid $brand-secondary; 
			border-bottom: 1px solid #fff; 
		} 
	}
}
@media screen and (max-width:767px) {
	.tab-set { display:block;  }
	.tab-set>li { float: none; margin-bottom: -1px; width:100%; border-right: 1px solid $almost-gray;  }
}

.tab-content { 
	padding: 1.2rem; 
	border: 1px solid $almost-gray; 
	> div { display: none; } 
	> div.active { /*display: block;*/ display:table; width:100%; }  /*use table to contain floating images */
    h3 { color: $brand-primary; font-weight: 700; }
}               


/* ACCORDION */
section#accordion { margin-top:0 }
.accordion {
	margin-bottom: 1rem;
	.accordion-label {  /* button */
		width: 100%;
		background: $white;
		color: $body;
		text-align: left;
		font-weight:bold; 
		padding: 1rem;
		border: 0; 
		cursor: pointer;

		&:before {
			content: "+";
			/*padding-right: 10px;  */
			float: left; margin-right:15px;
			font-size: 1em;
			font-weight: bold;
			color: $brand-secondary;
			transition: transform 0.3s ease-in-out;
		}
		&.active {
			&:before { transform:rotate(-135deg); }
		}
	}
	ul { margin: 0; padding: 0;	}
	li { list-style-type: none; }
	> ul > li { border-bottom: 1px solid $light-gray; }

	.accordion-content {
		max-height: 0; overflow: hidden; transition: all 0.35s ease-in-out;
		ul {
			padding-left: 2.5rem; padding-bottom: 1rem; 
		}	
		li {
			padding: .25rem 1rem;
			background: $white;
			list-style-type:disc;
		}
		div { padding: 0 1rem; }
		img { margin-bottom: 1rem;  }
	}
}

.box {
	transition: transform 0.1s;
	position: relative;
}

/* CARDS */
.card {
	border: none;
	box-shadow: $dp-2;
	border-radius: 0;
	.card-img-top { }
}
.card-bg-primary {
	background: $brand-primary;	
}
.card-bg-secondary {
	background: $brand-secondary;
}
.card-block {
	text-align:center;
	padding: 1rem .5rem;
}


/* EKKO-LIGHTBOX MODALS */
.modal-content {
	background: #ddd; padding-bottom:1rem; 
	border: none;
	border-radius: 0;
	& > .modal-header {
		border-bottom: 0;
		padding: 0;

		& > .close {
			color: #000; padding: .5rem 1rem 0; margin:0;
			opacity: 1;
			&:focus { opacity: 1;       }
			&:hover { opacity: 1;       }
		}
	}
	.ekko-lightbox-nav-overlay {
		top:43%; position:absolute; width:100%;
		& > a {
			color: $white;text-shadow: 0 0 10px #666; 
			padding: 0.5em;
			opacity: 1;
			font-size:46px;
			text-decoration:none;
			&:first-child { float:left; } 		
			&:last-child { float:right; margin-right:5%; } 
		}
	}
}
.modal-body { padding: .5rem 1rem; }	
.modal-footer { color:#000; padding: 0 1rem; border-top: 0;justify-content: left; }	


/* MASONRY GALLERY */
@media screen and (min-width:480px) { 	
	.masonry-3 { column-count: 2; column-gap: 2rem; }	
	.masonry-2 { column-count: 2; column-gap: 2rem; }
}
@media screen and (min-width:768px) { 	
	.masonry-3 { column-count: 3; column-gap: 2rem; }
	.masonry-1 { text-align:center;  }
	.masonry-1 img { max-width: 30%; height:auto; margin-left:1rem !important; margin-right:1rem !important; }	/* 1 horizontal row, not masonry */
}	
@media screen and (min-width:480px) and (max-width:767px) { 	
	.masonry-1 { column-count: 2; column-gap: 2rem; }
}		
.masonry-2 { max-width:730px; margin: 0 auto;  }	
.onpage-gallery img.aligncenter { display: inline; margin: 0 0 2rem; vertical-align: top;}	



.menu-social-media-container {
	a {
		display: block;
		height: 24px;
		width: 24px;
		text-indent: -9999em;
		overflow: hidden;
	}
	li {
		background-position: top center;
		background-size: contain;
		margin: 0 0.2rem 0 0;
	}
	.linkedIn {background-image: url('/wp-content/themes/tamarin/dist/images/32-linkedin.png');}
	.twitter {background-image: url('/wp-content/themes/tamarin/dist/images/32-twitter.png');}
	.facebook {background-image: url('/wp-content/themes/tamarin/dist/images/32-facebook.png');}
	.instagram {background-image: url('/wp-content/themes/tamarin/dist/images/32-instagram.png');}	
}


.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
	iframe {
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.video-container.shorts {
    padding-bottom: 177%;
}	


/* OFFCANVAS NAV */
.offcanvas-toggle.is-open .icon-bar:nth-child(3) {  
    -webkit-transform: rotate(-45deg) translate(5px,-5px);
    transform: rotate(-45deg) translate(5px,-5px);
}

#js-bootstrap-offcanvas { background: #fff; }
.navbar-toggle {
	.icon-bar {
		display: block;
		width: 25px;
		height: 3px;
		border-radius: 1px;
		background: $brand-primary; 
	}
	.icon-bar+.icon-bar {
		margin-top: 4px;
	}
}
header .nav-primary {
	.in .nav {
		display: table;
		& > li a { font-weight: 700;}
		& > li > ul.sub-menu { padding-left:0; }
		li {
			padding: 0;
			a { font-size: 16px; border-bottom: 1px solid $almost-gray; }
        	&.mobile { display:block; }			
		}
		li .sub-menu {
			display: block;
			position: relative;
			left: auto;
			top: auto;
			padding-left: 0;
			padding-top:0;
			padding-bottom: 0;

			a { font-weight: 400; padding: .5rem .5rem .5rem 1rem; }
			li .sub-menu {
				display: block;
				position: relative;
				left: auto;
				top: auto;
				padding-left: 20px; 
			}
		}
	}
}
header.banner .nav-primary .in li .sub-menu li { padding: 0 20px 0 0; }
header.banner .nav-primary .in li a { padding-left: 0; }
header.banner .nav-primary li.mobile.btn { margin:10px 0; }
header.banner .nav-primary li.mobile.btn a { font-size: .875rem; padding: 0.125rem 0.75rem; color:#fff; border-bottom:0;  }



/* media queries */
@media (max-width: 992px) {}
@media (max-width: 768px) {}

.row.no-gutters {
	margin-right: 0 !important;
	margin-left: 0 !important;
	& > [class^="col-"],
	& > [class*=" col-"] {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}


/* from /wp-content/themes/tamarin/bower_components/slick-carousel/slick/slick-theme.css */
@charset 'UTF-8';
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 38%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev { left: -25px; }
[dir='rtl'] .slick-prev { right: -25px; left: auto; }
.slick-prev:before { content: "←"; }
[dir='rtl'] .slick-prev:before { content: "←"; }

.slick-next {  right: -25px; }
[dir='rtl'] .slick-next { right: auto; left: -25px; }
.slick-next:before { content: "→"; color: #000; }
[dir='rtl'] .slick-next:before { content: "→"; color: #000; }

/* CUSTOM ARROWS */
.slick-next:before, [dir=rtl] .slick-next:before {
	content: "";
	background:url('/wp-content/uploads/2022/10/arrow-right.png') no-repeat;
	width:41px; height:71px; display:block;  
}	
.slick-prev:before, [dir=rtl] .slick-prev:before {
	content: "";
	background:url('/wp-content/uploads/2022/10/arrow-left.png') no-repeat;
	width:41px; height:71px; display:block;  
}	
.slick-prev { left: -40px; } .slick-next { right: -20px; }
.home .slick-next, .home .slick-prev { top: 20%;}
@media screen and (max-width:480px) { 	
	.slick-next:before, [dir=rtl] .slick-next:before, .slick-prev:before, [dir=rtl] .slick-prev:before { width:26px; background-size:26px; } 
}

/* Dots */
.slick-dotted.slick-slider {
    /* margin-bottom: 30px; */
	margin-bottom:0; 
}
.slick-dots {
    position: absolute;
    bottom: 30px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 16px;
    height: 16px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 27px;
    line-height: 20px;border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
	content: "";
    text-align: center;
    opacity: 1;
    color: black; background:#fff;
	border: 2px solid #fff;	
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: white;
    background: #ffd70d;
    border: 3px solid #ffd70d;
}
