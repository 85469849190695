.home {
	#hero { 
		background: $very-light-gray;
		margin:0;
		> .container { min-height:800px;  }
		h1 { 
			font-size: 3.5rem !important; margin-bottom: 1rem; margin-top: 3rem; font-weight: 300; line-height: 1; 
			/* &:after { display:none; }  removed the underline */
		}
		@include media-breakpoint-down(md) {  
			h1 { font-size: 2.5rem !important; } 
		}
		.btn { margin: 30px 0;  }
	}
	
	#services {
		.col-md-4 { margin-bottom: 30px; }	
		h2 {
			text-align: center; position:relative;
			font-size:1.5rem !important;
			font-weight: 400;
			margin: 1rem;
			a { color: #fff !important;  }
		}
		.box { margin-bottom: 1rem; }
		img { margin-bottom: 1rem; padding-bottom: 3rem; }
	} 
	
	#recent .block { 
		padding-bottom: 30px; 
		@include media-breakpoint-up(md) {  padding: 30px; } 
		h3 { 
			margin: 15px 0;  
			a { text-decoration:none;  } 	
			a:hover { text-decoration:underline;  }
		}
	}
	
	#carousel div.carousel-border {
    	border: 1px solid #909090;
    	padding: 20px 20px 90px;
		h2.title {
			background: #fff;
			position: relative;
			display: inline;
			padding: 0 20px;
			z-index: 50;
			top: -40px;
		}
		.slick-dots { bottom: -50px; }	
		.slick-dots li button:before {
			border: 2px solid #909090;
			background:#909090;
		}
		.slick-dots li.slick-active  button:before {
			border: 3px solid $gold;
			background: $gold;
		}
	}	
	
	/* #video { 
		background:#082259; color:#fff; padding: 3rem 0; margin:0; 
		h2 { position:relative; font-size:2.6rem !important; font-weight:300; margin:2rem 0 3rem; color:#fff; }
		h2:after {    content: ""; width: 95%; height: 4px; background: #ffd51e; position: absolute; left: 0; bottom: -1.5rem; }
		a.btn { background: #2b65c0;	margin-top:2rem; font-size: .875rem;  padding: 0.125rem 0.75rem; white-space:normal; }
	}  */

	#testimonials {
		h2 {
			margin-bottom: 3rem;
			position: relative;
		}
		h2:after {
			content:'';
			width: 2.5rem;
			height: 2px;
			background: $brand-secondary;
			position: absolute;
			left: 48%; /* temp */
			bottom: -0.75rem;
		}
		.slick-next::before, .slick-prev::before {
			color: $brand-secondary;
			font-size: 2rem;
		}
		.slick-prev { left: -4rem; }
		.slick-next { right: -4rem; }
	}
	.welcome { font-size:36px; color: $brand-primary; font-family: Oswald,sans-serif; font-weight:300; }	
	

} /* end home page styles */	



/* Various page panels */
#product-cta { background:#2c65bf; padding:2rem 0; margin:0; } 
#product-cta div.col-md-4 { padding-top: 10px; padding-bottom: 10px;   }	
@media screen and (min-width:768px) { #product-cta div.border-right { border-right: 2px solid #ffd21c !important;  } }
#product-cta a.btn { width:80%; }

#casestudy-panel { background:#eaeaea; padding:3rem 0; margin:0; }
#casestudy-panel h3 { position:relative; font-size:2.6rem !important; font-weight:300; margin:0 0 3rem; color:#082259; }
#casestudy-panel h3:after { content: ""; width: 300px; height: 4px; background: #ffd51e; position: absolute; left: 0; bottom: -1.5rem;}	
#casestudy-panel.dark-blue  { background:#082259; color:#fff; }	
#casestudy-panel.dark-blue h3 { color:#fff; }
#casestudy-panel div.photo img { margin-bottom: 15px; }		

#gallery-panel { background:#082259; color:#fff; padding: 3rem 0; margin:0; } 	
#gallery-panel h2 { position:relative; font-size:2.6rem !important; font-weight:300; margin:2rem 0 3rem; color:#fff; }
#gallery-panel h2:after { content: ""; width: 300px; height: 4px; background: #ffd51e; position: absolute; left: 0; bottom: -1.5rem;}	
	
#videos-panel { background:#082259; color:#fff; padding: 3rem 0 0; margin:0; } 
#videos-panel h2 { position:relative; font-size:2.6rem !important; font-weight:300; margin:2rem 0 3rem; color:#fff; }
#videos-panel h2:after { content: ""; width: 300px; height: 4px; background: #ffd51e; position: absolute; left: 0; bottom: -1.5rem;}
#videos-panel a.btn { background: #2b65c0;	margin-top:2rem; font-size: .875rem;  padding: 0.125rem 0.75rem; white-space:normal;}	

/* VIDEOS PAGE 
#videos .row > div { margin-bottom:30px;  }  */

/* CAREERS PAGE */
.career-post-sidebar { .btn { width:100%; } }
.career-post-footer { @include media-breakpoint-down(md) { display:none; } }


/* Roll up text */
.rollover-roll-text {
	.caption-wrapper {
		color: $white;
		position: absolute;
		max-width: 100%;
		height: 0;
		overflow: hidden;
		bottom: 0;
		left: 0;
		right: 0;
		text-align:center;
		background: rgba(0, 0, 0, 0.5);
		transition: height 0.3s;
		.caption-inner {
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
	    }
	}
	.caption-title {
		background: $brand-primary;	
		position:absolute; 
		bottom:0; 
		width:100%;
		z-index:5;
    }	
	&:hover {
    	.caption-wrapper { height: 100%; }
  	}
}

/* image gallery */
.product-image-gallery {
	padding: 0;
 	li {
		list-style-type: none;
		float: left;
		display: none;
		padding: 3px;

		&:first-child {
			display: block;
		}
		img {
			width: 100%;
			height: 100%;
			max-height: 100%;
		}
		.card-block {
			p {
				display: block;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				max-height: 3em;
				line-height: 1.5em;
				margin-bottom: 0;
			}
		}
	}
}
.product-image-gallery-nav {
	padding: 0;
	li {
		list-style-type: none;
		float: left;
		/*max-width: 147px;  1920 width mainly the max for this */
		display: none;
		margin: 0 0.25em;
		transition: border-bottom 0.1s;
		border-bottom: 0.25em solid $white;
		img {
			padding: 0.25em 0;
			&:hover {
				cursor: pointer;
			}
		}
	}
	li.slick-current {
		border-bottom: 0.25em solid $brand-secondary;
	}
}


/*  media queries  */
@media (max-width: 1200px) {}
@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}

// Webkit
@-webkit-keyframes wobble  {
  0%  { -webkit-transform:  rotate(0deg); }
  20%  { -webkit-transform:  rotate(2deg); }
  50%  { -webkit-transform:  rotate(-2deg); }
  100%  { -webkit-transform:  rotate(0deg); }
}